/* eslint-disable jsx-a11y/no-autofocus */
import {
  Avatar,
  IconButton,
  InputGroup,
  InputRightElement,
  StyleProps,
  Textarea,
  Tooltip,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { defaultLogoUrl } from "@constants";
import { IMessage } from "@CustomTypes/message.type";
import { useConfig } from "@hooks/config";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImAttachment } from "react-icons/im";
import { MdArrowUpward } from "react-icons/md";
import { useSelector } from "react-redux";
import short from "short-uuid";

import store from "@/store/store";

import ChatActionManager from "./ChatTypingAction";
import ExpectedReplyHandler from "./ExpectedReplyHandler";
import UploadDialog from "./UploadDialog";

const ChatInput = (style: StyleProps) => {
  const [msgContent, setMsgContent] = useState("");
  const botImg = useSelector(store.select.configModel.botImage);
  const config = useConfig();
  const { t } = useTranslation();
  const ref = useRef<HTMLTextAreaElement>(null);
  const typing = useSelector(store.select.chatActionModel.action);
  const botName = useSelector(store.select.configModel.botName);
  const toast = useToast({
    icon: <Avatar name={botName} src={botImg || defaultLogoUrl} />,
  });
  const {
    isOpen: isUploadDialogOpen,
    onOpen: onUploadDialogOpen,
    onClose: onUploadDialogClose,
  } = useDisclosure();

  const resetTextarea = () => {
    const textarea = ref.current;
    if (textarea) {
      if (textarea.value.trim().length === 0) {
        textarea.style.height = "47px";
        textarea.rows = 1;
      } else {
        textarea.style.height = "47px";
      }
      if (textarea.scrollHeight > 163) {
        textarea.style.height = "163px";
      } else textarea.style.height = Math.max(textarea.scrollHeight, 47) + "px";
    }
  };

  const sendMessage = async () => {
    const content = msgContent.trim();
    if (content.length === 0) return;

    const message: IMessage = {
      id: short.generate(),
      text: content.substring(0, 1000),
      type: "Text",
      timestamp: new Date(),
      role: "User",
      identity: config.identity,
    };

    try {
      // store.dispatch.chatModel.sendMessage(message);
      await store.dispatch.chatModel.sendMessageAndWait(message);
    } catch (e) {
      toast({
        title: "😥 Something Went Wrong!",
        description:
          "Please Try Again Later! If this issue persist please contact support.",
        variant: "error",
      });
    }

    // store.dispatch.chatModel.addNewMember("Raj");
    if (ref.current) {
      setMsgContent("");
      ref.current.focus();
    }
  };

  useEffect(() => {
    // if (!msgContent.length) return;
    resetTextarea();
  }, [msgContent]);

  // useEffect(() => {
  //   if (!typing && ref.current) {
  //     ref.current.focus();
  //   }
  // }, [typing]);

  return (
    <>
      <VStack
        px={2}
        spacing={0}
        width="100%"
        alignItems="left"
        // height={"73px"}
        bg={"inherit"}
        {...style}
      >
        <ExpectedReplyHandler width={"95%"} />
        <InputGroup width={"100%"} size="sm" position="relative">
          <Textarea
            shadow={"md"}
            size="sm"
            borderRadius="lg"
            resize="none"
            overflow="hidden"
            rows={1}
            pr={12}
            pl={2}
            py={3}
            fontSize={{
              base: "16px",
              md: "14px",
            }}
            maxLength={1000}
            borderColor="brand.secondary"
            focusBorderColor="brand.secondary"
            _hover={{
              borderColor: "brand.secondary",
            }}
            autoFocus={false}
            ref={ref}
            bg="white"
            onChange={(t) => setMsgContent(t.target.value)}
            value={msgContent}
            placeholder={t("Enter your message")}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                if (event.shiftKey) {
                  setMsgContent((value) => value + "\n");
                  return;
                }
                if (!typing) sendMessage();
              }
            }}
          />
          <InputRightElement
            justifyContent={"right"}
            width="4.5rem"
            height={"100%"}
            right={2}
            gap={2}
          >
            <Tooltip hasArrow label={t("Upload")}>
              <IconButton
                hidden={msgContent.length > 0}
                isDisabled={typing !== undefined}
                aria-label={t("Upload")}
                icon={<ImAttachment />}
                size={"sm"}
                _hover={{
                  bg: "brand.otherChatBubbleBackground",
                  color: "brand.otherChatBubbleText",
                }}
                borderRadius="full"
                onClick={onUploadDialogOpen}
                isLoading={false}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              offset={[-50, 0]}
              label={t("Send") + " ( ⏎ ENTER )"}
            >
              <IconButton
                color="brand.onSecondary"
                isDisabled={typing !== undefined}
                aria-label={t("Send")}
                icon={<MdArrowUpward size="22" />}
                size={"sm"}
                bg="brand.primary"
                _hover={{
                  bg: "brand.primary",
                }}
                borderRadius="full"
                onClick={sendMessage}
              />
            </Tooltip>
          </InputRightElement>
        </InputGroup>
        <ChatActionManager minHeight={"26px"} />
      </VStack>
      <UploadDialog
        canClose={true}
        isOpen={isUploadDialogOpen}
        onOpen={onUploadDialogOpen}
        onClose={onUploadDialogClose}
        supportedMimeType={["image/*", "video/*", "audio/*", "application/pdf"]}
      />
    </>
  );
};

export default ChatInput;
