import {
  IChatAction,
  IMessageLinkPreview,
  IResponseMessage,
} from "@CustomTypes/message.type";
import { useConfig, useMessenger } from "@hooks/config";
import useLogging from "@hooks/logging";
import { getUniqueIdentity } from "@utils/identity";
import { throttle } from "@utils/utilities";
import { encode } from "js-base64";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { generate } from "short-uuid";

import store from "@/store/store";

function StreamReplyController() {
  const logging = useLogging();
  const [source, setSource] = useState<EventSource>();
  const config = useConfig();
  const messenger = useMessenger();
  const baseUrl = `${config.endpoint}/get_response/${config.projectId}/`;
  const activePayload = useSelector(store.select.responseModel.activePayload);

  const pushMessageToSurface = (text?: string) => {
    if (!text) return;
    messenger.sendMessage({
      event: "message",
      payload: {
        text: text,
      },
    });
  };

  const waitForResponse = async () => {
    const payload = encode(JSON.stringify(activePayload));
    let url = `${baseUrl}?payload=${payload}&verify_key=${config.verifyKey}`;

    if (config.otid) {
      url += `&t=${config.otid}`;
    }
    if (config.wfid) {
      url += `&wf=${config.wfid}`;
    }

    const evSrc = new EventSource(url);
    setSource(evSrc);

    let partialMessageId: string = generate();
    const timestamp = new Date().getTime();
    const message = {
      id: partialMessageId,
      timestamp,
      text: "",
      type: "Text",
      role: "Agent",
      identity: config.identity ?? getUniqueIdentity(),
    } as IResponseMessage;
    const chunks: string[] = [];
    const lastLinkPreviews: IMessageLinkPreview[] = [];

    const delayedChunkPush = throttle((ev: MessageEvent) => {
      logging.debug("Message Chunk: ", ev);
      store.dispatch.chatModel.addNewMessage(message);
      const chunkText = JSON.parse(ev.data).chunk;

      store.dispatch.chatModel.updatePartialMessage({
        id: partialMessageId,
        text: chunkText,
        type: "Text",
      });
    }, config.typingDelay);

    evSrc.addEventListener("open", (ev) => {
      logging.debug("Stream Connetion Connection: ", ev);
    });
    evSrc.addEventListener("chat.init", (ev: MessageEvent) => {
      logging.debug("Chat Initialized: ", ev);
      store.dispatch.chatModel.setChatId(JSON.parse(ev.data).chatId);
    });
    evSrc.addEventListener("chat.msg_chunk", (ev: MessageEvent) => {
      const chunkText = JSON.parse(ev.data).chunk;
      chunks.push(chunkText);
      delayedChunkPush(ev);
    });
    evSrc.addEventListener("chat.action", (ev: MessageEvent) => {
      logging.debug("Chat Action: ", ev);
      store.dispatch.responseModel.updateChatAction(
        JSON.parse(ev.data) as IChatAction
      );
    });
    evSrc.addEventListener(
      "chat.message",
      throttle((ev: MessageEvent) => {
        logging.debug("Chat Message: ", ev);
        const msg = JSON.parse(ev.data) as IResponseMessage;
        msg.timestamp = new Date().getTime();
        if (!msg.links) {
          msg.links = lastLinkPreviews;
        }
        partialMessageId = msg.id;
        store.dispatch.chatModel.receivedMessage(msg);

        // Send message to the parent
        pushMessageToSurface(msg.text);
      }, 1000)
    );
    evSrc.addEventListener("chat.link_preview", (ev: MessageEvent) => {
      logging.debug("Chat Link Preview: ", ev);
      const linkPreview = JSON.parse(ev.data) as IMessageLinkPreview;
      lastLinkPreviews.push(linkPreview);
      store.dispatch.chatModel.updatePartialMessage({
        id: partialMessageId,
        linkPreview: {
          ...linkPreview,
          id: generate(),
        },
        type: "Text",
      });
    });
    evSrc.addEventListener("close", (ev) => {
      logging.debug("Closed Signal Received: ", ev);
      store.dispatch.responseModel.settleAll();
      // Send message to the parent
      pushMessageToSurface(chunks.join(""));
      evSrc.close();
    });
    evSrc.addEventListener("error", (ev) => {
      logging.error("Streaming Error Occured: ", ev);
      store.dispatch.responseModel.settleAll();
      evSrc.close();
    });
  };

  useEffect(() => {
    if (source) {
      !source.CLOSED && source.close();
      setSource(undefined);
    }

    if (!activePayload) return;
    waitForResponse();
    return () => {
      source?.close();
    };
  }, [activePayload]);

  return <></>;
}

export default StreamReplyController;
