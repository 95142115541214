/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-children-prop */
import {
  Avatar,
  Box,
  HStack,
  Image,
  Link,
  StyleProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BubblePlacement } from "@CustomTypes/chat_bubble.type";
import { IMessage } from "@CustomTypes/message.type";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  MdAudioFile,
  MdContactPhone,
  MdFilePresent,
  MdImage,
  MdVideoFile,
} from "react-icons/md";
import ReactMarkdown from "react-markdown";
import { CodeProps } from "react-markdown/lib/ast-to-react";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark as highlightTheme } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactTimeAgo from "react-time-ago";
import remarkBreaks from "remark-breaks";

import AgentAvatar from "./AgentAvatar";
import LinkPreview from "./LinkPreview";

type IProps = StyleProps & {
  message: IMessage;
  placement: BubblePlacement;
  username: string;
};

function CodeBlock({ children, className }: CodeProps) {
  const language = /language-(\w+)/.exec(className || "language-python") ?? "";

  return (
    <SyntaxHighlighter
      customStyle={{
        borderRadius: "8px",
      }}
      language={language[1]}
      style={highlightTheme}
    >
      {children as any}
    </SyntaxHighlighter>
  );
}

const ChatItem = ({ message, placement, username, ...rest }: IProps) => {
  const { t, i18n } = useTranslation();
  const onLeftAligned = message.role === "Agent";
  const hideAvatar = placement !== "end";
  const bg = onLeftAligned
    ? "brand.chatBubbleBackground"
    : "brand.otherChatBubbleBackground";
  const fg = onLeftAligned
    ? "brand.chatBubbleText"
    : "brand.otherChatBubbleText";

  const borderRadius = () => {
    if (onLeftAligned) {
      switch (placement) {
        case "top":
          return "8px";
        case "end":
        default:
          return "8px 8px 8px 0";
      }
    } else {
      switch (placement) {
        case "top":
          return "8px";
        case "end":
        default:
          return "8px 8px 0 8px";
      }
    }
  };

  const escapeBreak = useCallback(
    (text: string) => {
      const _text = text.replaceAll("\n", "\n&nbsp;&nbsp;");
      return _text;
    },
    [message]
  );

  const fixtag = (props) => {
    const element = props.children;
    const node = props.node;
    return ["img", "pre"].includes(node.tagName) ? (
      { ...element }
    ) : (
      <p style={{ margin: "5px 0px" }} {...props} />
    );
  };

  const linkStyle = (props) => {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <Link
        className="anchor"
        fontWeight={"medium"}
        textDecoration={"underline"}
        isExternal
        {...props}
      />
    );
  };

  const listItem = (props) => {
    return <li style={{ margin: "5px 0 5px 15px" }} {...props} />;
  };

  const imgItem = (props) => {
    return (
      // <Image
      //   loading="eager"
      //   fallback={<></>}
      //   borderRadius={"5"}
      //   my={"2"}
      //   maxH="200px"
      //   {...props}
      // />
      // eslint-disable-next-line jsx-a11y/alt-text
      <img
        style={{
          borderRadius: "5px",
          marginTop: "2px",
          marginBottom: "2px",
          maxHeight: "200px",
        }}
        {...props}
      />
    );
  };

  const getFileIcon = () => {
    switch (message.type) {
      case "Audio":
        return <MdAudioFile size={"30%"} />;
      case "Video":
        return <MdVideoFile size={"30%"} />;
      case "Image":
        return <MdImage size={"30%"} />;
      default:
        return <MdFilePresent size={"30%"} />;
    }
  };

  const displayMessage = () => {
    switch (message.type) {
      case "Text": {
        if (onLeftAligned) {
          return (
            <Box
              textAlign="left"
              fontSize={"md"}
              px={2}
              py={1}
              maxW={"100%"}
              overflowX="auto"
            >
              <ReactMarkdown
                components={{
                  code: CodeBlock,
                  p: fixtag,
                  a: linkStyle,
                  li: listItem,
                  img: imgItem,
                }}
                remarkPlugins={[remarkBreaks]}
              >
                {message.text?.trim() ?? ""}
              </ReactMarkdown>
              <LinkPreview previews={message.links} />
            </Box>
          );
        }
        return (
          <Box textAlign="left" fontSize={"md"} px={2} py={2} maxW={"100%"}>
            <ReactMarkdown remarkPlugins={[remarkBreaks]}>
              {escapeBreak(message.text ?? "").trim()}
            </ReactMarkdown>
          </Box>
        );
      }
      case "Phone":
        return (
          <HStack
            fontSize={"md"}
            px={2}
            py={1}
            spacing="2.5"
            fontWeight="semibold"
          >
            <MdContactPhone />
            <Text textAlign="left" fontSize="sm">
              {message.phone}
            </Text>
          </HStack>
        );
      case "Document":
      case "Image":
      case "Video":
      case "Audio":
        return (
          <VStack
            fontSize={"md"}
            px={2}
            py={4}
            spacing="2.5"
            fontWeight="semibold"
          >
            {getFileIcon()}
            <Text textAlign="left" fontSize="sm" wordBreak={"break-word"}>
              {message.text}
            </Text>
          </VStack>
        );
      case "Location":
        return (
          <Image
            alt="location"
            fontSize={"md"}
            px={2}
            py={1}
            borderRadius="8%"
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${
              message.location?.lat
            },${message.location?.long}&markers=color:${"black"}|${
              message.location?.lat
            },${
              message.location?.long
            }&zoom=20&size=180x180&key=AIzaSyC439NmHL6Fw2PYbMv3K8cDFQC0k8K3fhc`}
          />
        );
      default:
        return (
          <Text p="2" fontSize={"sm"} fontStyle={"italic"}>
            Unsupported Type
          </Text>
        );
    }
  };

  // Hide Start command
  if (message.text === "/start") {
    return (
      <Box p={3} id={`msg_$${message.id}`}>
        <Text fontSize={"xs"} fontWeight={"medium"} color="brand.text">
          {t("Chat Started")} (
          <ReactTimeAgo
            date={new Date(message.timestamp)}
            locale={i18n.language}
          />
          )
        </Text>
      </Box>
    );
  }
  if (message.text === "/skip") {
    return (
      <Box p={3} id={`msg_$${message.id}`}>
        <Text fontSize={"xs"} fontWeight={"medium"} color="brand.text">
          {t("Question Skipped")}
        </Text>
      </Box>
    );
  }

  return (
    <VStack
      width="100%"
      spacing={1}
      alignItems={onLeftAligned ? "start" : "end"}
      {...rest}
    >
      <HStack
        width="100%"
        alignItems="end"
        flexDir={onLeftAligned ? "row" : "row-reverse"}
        spacing={[1.5, 2.5]}
      >
        {!hideAvatar &&
          (onLeftAligned ? (
            <AgentAvatar name={username} />
          ) : (
            <Avatar size={"xs"} name={t(username)} />
          ))}
        <Box
          shadow="sm"
          key={message.id}
          maxW="85%"
          ml={hideAvatar ? "28px" : 0}
          mr={!hideAvatar ? 0 : "28px"}
          bgColor={bg}
          borderRadius={borderRadius()}
          color={fg}
        >
          {displayMessage()}
        </Box>
      </HStack>
      {!hideAvatar && (
        <HStack
          id={`msg_$${message.id}`}
          spacing="0"
          flexDir={onLeftAligned ? "row" : "row-reverse"}
          gap="1"
        >
          <Text
            fontSize="xs"
            fontWeight={"semibold"}
            color={"brand.text"}
            textTransform="capitalize"
            pr={!onLeftAligned ? 1.5 : 0}
          >
            {t(username)}
          </Text>
          <span>{"•"}</span>
          <Text fontSize="xs" color="brand.text">
            <ReactTimeAgo
              updateInterval={10000}
              date={message.timestamp}
              locale={i18n.language}
              tooltip={true}
              timeStyle="round-minute"
            />
          </Text>
        </HStack>
      )}
    </VStack>
  );
};

export default ChatItem;
