import {
  Button,
  Card,
  Collapse,
  StyleProps,
  Text,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { IMessage } from "@CustomTypes/message.type";
import { useConfig } from "@hooks/config";
import { scrollToMessage } from "@utils/utilities";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdOutlineContactPhone, MdShareLocation } from "react-icons/md";
import { useSelector } from "react-redux";
import short from "short-uuid";

import store from "@/store/store";

import PhoneDialog from "./PhoneDialog";

const ExpectedReplyHandler = (style: StyleProps) => {
  const aer = useSelector(store.select.chatModel.agentExpectedReply);
  const chatAction = useSelector(store.select.chatActionModel.action);
  const config = useConfig();
  const lastMessageId = useSelector(store.select.chatModel.lastMessageId);
  const isWaiting = useSelector(store.select.responseModel.isWaiting);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isPhoneDialogOpen,
    onOpen: onPhoneDialogOpen,
    onClose: onPhoneDialogClose,
  } = useDisclosure();

  const chatContainer = document.getElementById(config.containerId);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    switch (aer?.type) {
      case "contact":
      case "location":
      case "choice":
      case "document":
        onOpen();
        scrollToMessage(lastMessageId, 200, chatContainer);
        break;
      default:
        {
          if (aer?.allow_skip) {
            onOpen();
            scrollToMessage(lastMessageId, 200, chatContainer);
          } else onClose();
        }
        break;
    }
  }, [aer]);

  const handleOptionTap = async (value: string) => {
    const message: IMessage = {
      id: short.generate(),
      text: value.trim(),
      type: "Text",
      role: "User",
      timestamp: new Date().getTime(),
      identity: config.identity,
    };
    // store.dispatch.chatModel.sendMessage(message);
    await store.dispatch.chatModel.sendMessageAndWait(message);
    onClose();
  };

  const handleLocation = () => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        const message: IMessage = {
          id: short.generate(),
          location: {
            lat: pos.coords.latitude,
            long: pos.coords.longitude,
          },
          role: "User",
          type: "Location",
          timestamp: new Date().getTime(),
          identity: config.identity,
        };
        // store.dispatch.chatModel.sendMessage(message);
        await store.dispatch.chatModel.sendMessageAndWait(message);
        onClose();
        setLoading(false);
      },
      (err) => {
        alert(JSON.stringify(err));
        console.error(err);
        setLoading(false);
      }
    );
  };

  const handlePhone = () => {
    onPhoneDialogOpen();
  };

  const handleSkip = async () => {
    const message: IMessage = {
      id: short.generate(),
      text: "/skip",
      type: "Text",
      role: "User",
      timestamp: new Date().getTime(),
      identity: config.identity,
    };
    await store.dispatch.chatModel.sendMessageAndWait(message);
  };

  return (
    <>
      <Collapse
        delay={{
          enter: 850,
          exit: 0,
        }}
        in={isOpen}
        animateOpacity={true}
      >
        <Card
          bg="brand.chatBubbleBackground"
          p="1.5"
          shadow={"md"}
          mx={"auto"}
          borderRadius={"lg"}
          borderBottomRadius={0}
          {...style}
        >
          <Wrap justify="center" spacing="2">
            {["choice", "gender"].includes(aer?.type ?? "") &&
              aer?.options?.map((item) => (
                <Button
                  key={item}
                  bg="brand.otherChatBubbleBackground"
                  color="brand.otherChatBubbleText"
                  size="sm"
                  _hover={{
                    background: "brand.secondary",
                  }}
                  onClick={() => handleOptionTap(item)}
                  isDisabled={chatAction !== undefined || isWaiting}
                >
                  <Text
                    _firstLetter={{
                      textTransform: "uppercase",
                    }}
                  >
                    {item}
                  </Text>
                </Button>
              ))}
            {aer?.type === "location" && (
              <Button
                size="sm"
                bg="brand.otherChatBubbleBackground"
                color="brand.otherChatBubbleText"
                onClick={handleLocation}
                isLoading={loading}
                leftIcon={<MdShareLocation size={"22px"} />}
                isDisabled={chatAction !== undefined || isWaiting}
              >
                {t("Share Location")}
              </Button>
            )}
            {aer?.type === "contact" && (
              <Button
                size="sm"
                leftIcon={<MdOutlineContactPhone size={"22px"} />}
                bg="brand.otherChatBubbleBackground"
                color="brand.otherChatBubbleText"
                onClick={handlePhone}
                isLoading={loading}
                isDisabled={chatAction !== undefined || isWaiting}
              >
                {t("Share Phone Number")}
              </Button>
            )}
            {/* {aer?.type === "document" && (
              
            )} */}

            {aer?.allow_skip && (
              <Button
                bg="brand.otherChatBubbleBackground"
                color="brand.otherChatBubbleText"
                size="sm"
                onClick={handleSkip}
                isDisabled={chatAction !== undefined || isWaiting}
              >
                {t("Skip")}
              </Button>
            )}
          </Wrap>
        </Card>
      </Collapse>
      <PhoneDialog
        canClose={aer?.allow_skip}
        isOpen={isPhoneDialogOpen}
        onOpen={onPhoneDialogOpen}
        onClose={onPhoneDialogClose}
      />
    </>
  );
};

export default ExpectedReplyHandler;
