/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IChat,
  IExpectType,
  IMessage,
  IPayloadMessage,
  IResponseMessage,
  MessageChunk,
} from "@CustomTypes/message.type";
import { createModel } from "@rematch/core";
import { getUniqueIdentity } from "@utils/identity";
import { generate } from "short-uuid";

import { IRootModel } from ".";

type IUserInfo = {
  name?: string;
  email?: string;
};

type IStartChatPayload = {
  userInfo: IUserInfo | null;
  session: string | null;
  identity: string | null;
};

type IChatMetaInfo = IUserInfo & {
  meta?: {
    [key: string]: any;
  };
};

type IChatState = {
  chatId?: string;
  chats: Record<string, IChat>;
  chat: IChat;
  expect?: IExpectType;
  isHumanAgent: boolean;
  chatMetaInfo?: IChatMetaInfo;
  sessionClosed: boolean;
  lastUpdate?: number;
};

const initialState: IChatState = {
  chats: {},
  chat: {
    messages: [],
    members: ["Agent", "User"],
    messagesIds: {},
    messageIdToIndex: {},
    session: generate(),
  },
  isHumanAgent: false,
  sessionClosed: true,
};

const chatModel = createModel<IRootModel>()({
  state: initialState, // initial state
  selectors: (slice) => ({
    agentExpectedReply() {
      return slice((state) => {
        return state.expect;
      });
    },
    userInfo() {
      return slice((state) => {
        return {
          name: state.chatMetaInfo?.name,
          email: state.chatMetaInfo?.email,
        };
      });
    },
    lastMessageId() {
      return slice((state) => {
        if (state.chat.messages.length === 0) return;
        return state.chat.messages[state.chat.messages.length - 1]?.id;
      });
    },
    lastUpdate() {
      return slice((state) => {
        return state.lastUpdate;
      });
    },
    username() {
      return slice((state) => {
        return state.chatMetaInfo?.name;
      });
    },
    messages() {
      return slice((state) => {
        return state.chat.messages;
      });
    },
    activeChat() {
      return slice((state) => {
        return state.chatId;
      });
    },
    chatMetaInfo() {
      return slice((state) => {
        return state.chatMetaInfo ?? {};
      });
    },
    isSessionClosed() {
      return slice((state) => {
        return state.sessionClosed;
      });
    },
  }),
  reducers: {
    setChatId(state, payload: string) {
      state.chatId = payload;
    },
    updateUserInfo(state, { name, email }: { name?: string; email?: string }) {
      state.chatMetaInfo = {
        ...state.chatMetaInfo,
        name: name ?? state.chatMetaInfo?.name,
        email: email ?? state.chatMetaInfo?.email,
      };
    },
    addMetaInfo(state, payload: Record<string, any>) {
      state.chatMetaInfo = {
        ...state.chatMetaInfo,
        meta: {
          ...state.chatMetaInfo?.meta,
          ...payload,
        },
      };
    },
    removeMetaInfo(state, key: string[]) {
      if (state.chatMetaInfo) {
        for (const k of key) {
          delete state.chatMetaInfo.meta?.[k];
        }
      }
    },
    addNewMessage(state, payload: IMessage) {
      if (state.chat.messagesIds[payload.id]) {
        return;
      }
      console.log(payload.id, "addNewMessage");

      state.chat.messagesIds[payload.id] = true;
      state.chat.messages.push(payload);
      state.chat.messageIdToIndex[payload.id] = state.chat.messages.length - 1;
      state.lastUpdate = new Date().getTime();
    },
    updatePartialMessage(state, payload: MessageChunk) {
      console.log(payload.id, "addNewMessage");

      if (!state.chat.messagesIds[payload.id]) {
        return;
      }

      const index = state.chat.messageIdToIndex[payload.id];
      const message = state.chat.messages[index];
      if (
        message.type === payload.type &&
        (payload.text || payload.linkPreview)
      ) {
        if (payload.text) {
          message.text += payload.text;
        }

        if (payload.linkPreview) {
          message.links = [...(message.links ?? []), payload.linkPreview];
        }
        state.chat.messages[index] = message;
      }
    },
    addResponseExpect(state, payload?: IExpectType) {
      state.expect = payload;
    },
    setSessionClosed(state, payload: boolean) {
      state.sessionClosed = payload;
    },
    setSession(state, session: string) {
      state.chat.session = session;
    },
    moveChatToArchive(state) {
      if (state.chatId) {
        state.chats[state.chatId] = state.chat;
      }

      state.chat = {
        messages: [],
        members: ["Agent", "User"],
        messagesIds: {},
        messageIdToIndex: {},
        session: generate(),
      };
      state.expect = undefined;
      state.chatId = undefined;
      state.isHumanAgent = false;
    },
  },
  effects: (dispatch) => ({
    async sendMessageAndWait(payload: IMessage, state) {
      dispatch.chatModel.addNewMessage(payload);
      const language = state.configModel.language;
      const message: IPayloadMessage = {
        ...payload,
        info: {
          user: state.chatModel.chatMetaInfo ?? {},
          identity: payload.identity ?? getUniqueIdentity(),
        },
        session: state.chatModel.chat.session,
        lang: language,
      };

      dispatch.responseModel.setupConnection({
        message: message,
      });
      dispatch.responseModel.updateChatAction({
        type: "TYPING",
        timeout: 60,
      });
    },
    receivedMessage(payload: IResponseMessage) {
      dispatch.chatModel.addNewMessage(payload);
      if (payload.remove_keyboard) {
        dispatch.chatModel.addResponseExpect(undefined);
        return;
      }
      dispatch.chatModel.addResponseExpect(payload.expect);
    },
    sendStartMessage(
      info: {
        session?: string | null;
        identity?: string | null;
      },
      state
    ) {
      dispatch.chatModel.setSessionClosed(false);
      if (info.session) dispatch.chatModel.setSession(info.session);
      const messages = state.chatModel.chat.messages;
      const startMessage: IMessage = {
        id: "start_message",
        text: "/start",
        type: "Text",
        timestamp: new Date().getTime(),
        role: "User",
        identity: info.identity ?? getUniqueIdentity(),
      };
      if (messages.length < 2) {
        dispatch.chatModel.sendMessageAndWait(startMessage);
      }
    },
    restartChat(identity: string | null, state): void {
      if (!state.chatModel.sessionClosed) return;
      dispatch.chatModel.sendStartMessage({
        identity: identity,
      });
    },
    startChat(payload: IStartChatPayload) {
      if (payload.userInfo) dispatch.chatModel.updateUserInfo(payload.userInfo);
      dispatch.chatModel.sendStartMessage({
        session: payload.session,
        identity: payload.identity,
      });
    },
    closeSession() {
      dispatch.chatModel.setSessionClosed(true);
      dispatch.chatModel.moveChatToArchive();
    },
  }),
});
export default chatModel;
